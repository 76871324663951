import * as React from 'react';

import styled from 'styled-components';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Card, Col, Row } from 'antd';

import Content from '../content';

const Wrapper = styled.div`
	.feature {
		height: 100%;
		
		.ant-card-head-title {
			font-weight: 800;
		}
	}
`;

interface Props {

}

interface State {

}

const features = [{
	title      : 'Block by groups',
	description: `All our rules are groupped together into groups 
	that can be enabled or disabled at any time.`,
	icon       : ``,
}, {
	title      : 'Create your own rules',
	description: `If our database is missing some entries or you want 
	to block something you can create your own set of rules.`,
	icon       : ``,
}, {
	title      : 'Bypass geo restrictions',
	description: `Many websites filter their requests and geo-restrict 
	their content based on DNS location. You can now bypass these limitations.`,
	icon       : ``,
}, {
	title      : 'Bypass ISP restrictions',
	description: `A lot of time, the ISP is filtering, blocking or 
	altering the DNS results. You can now bypass these limitations.`,
	icon       : ``,
}, {
	title      : 'Choose your location',
	description: `Our servers are groupped together by location and 
	you can pick any server that suits your specific needs.`,
	icon       : ``,
}, {
	title      : 'Customize by connection',
	description: `Our rules can be enabled by groups and each connection 
	you define can have a different set of groups enabled
	or disabled.`,
	icon       : ``,
}, {
	title      : 'Lockdown mode',
	description: `In lockdown mode all requests are denied except for
	the ones that are whitelisted by you.`,
	icon       : ``,
}, {
	title      : 'Safe search option',
	description: `When SafeSearch is on, it helps block explicit images, 
	videos, and websites from search engine results.`,
	icon       : ``,
}, {
	title      : 'Age restrictions',
	description: `Each connection you define can have a minimum age
	specified. Each rule that is supposed to be age restricted will
	then be checked against the age of the connection.`,
	icon       : ``,
}, {
	title      : 'API available',
	description: `You can programatically configure your connections
	and rules using our API. All of our endpoints are exposed via API.`,
	icon       : ``,
}, {
	title      : 'Light on resources',
	description: `This is a DNS server and all filtering and processing 
	is done on our servers. This saves you battery or energy.`,
	icon       : ``,
}, {
	title      : 'Save bandwidth',
	description: `Since we block everything at a DNS level no requests
	can go through so you save bandwidth by blocking ads, images, videos
	and other unwanted content.`,
	icon       : ``,
}, {
	title      : 'Watch regional shows',
	description: `Most streaming websites are offering shows that can be
	viewed only in certain regions. You can enable these shows by picking
	a DNS Server from that specific region.`,
	icon       : ``,
}, {
	title      : '180+ DNS servers',
	description: `We have over 180 dedicated servers covering all major
	regions of the globe and we're constantly working on enhancing our
	infrastructure.`,
	icon       : ``,
}, {
	title      : 'Time Window activated rules',
	description: `You can create rules that are active only within a
	predefined time window. Outside of that window the rules are inactive.`,
	icon       : ``,
	comingSoon : true,
}, {
	title      : 'Over 1.5M rules in our database',
	description: `Our bots are constantly looking for fresh lists of
	domains that fit into our blocking category. The list is then checked
	and maintained weekly.`,
	icon       : ``,
}, {
	title      : 'Whitelist domains',
	description: `Rules can be added to whitelist or overwrite the global
	ones that we maintain. If you want to unlock a domain you can add it
	to your whitelisted rules.`,
	icon       : ``,
}, {
	title      : 'Helps keep your privacy',
	description: `A big part of our blocked domains fall into the "Tracking"
	category. By blocking tracking websites we help you maintain your privacy.`,
	icon       : ``,
}, {
	title      : 'Parental controls',
	description: `With time window activated rules, SafeSearch, Lockddown,
	Adult content blocking and age restricted content you can protect 
	your children online.`,
	icon       : ``,
}, {
	title      : 'Protected from malware/phishing',
	description: `Our list contains tens of thousands of domains known to
	distribute malware and phishing. Blocking these requests will
	keep your computer safe.`,
	icon       : ``,
}, {
	title      : 'Bypass ISP history gathering',
	description: `Many internet service providers retain your internet
	history by collecting DNS records. The data can be used for ads
	or even handed over to the government.`,
	icon       : ``,
}, {
	title      : 'DNS over TLS (DOT)',
	description: `Your DNS queries are encrypted and secure. It's like
	https but for DNS. This prevents eavesdropping and manipulation of 
	DNS data via man-in-the-middle attacks.`,
	icon       : ``,
}, {
	title      : 'DNS over HTTPS (DOH)',
	description: `Your DNS queries are using the regular https port 443
	and look like regular web traffic. This also prevents eavesdropping 
	and man-in-the-middle attacks.`,
	icon       : ``,
}];

export default class Features extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return (
			<Wrapper {...this.props}>
				<Content padding={'0 24px'}>
					<Row gutter={48}>
						{features.map((feature, key) => {
							return (
								<Col lg={8} md={12} xs={24} key={key} style={{ marginTop: 20 }}>
									<Card title={<div>
										<LegacyIcon type={'check'} style={{
											color: 'var(--color-primary)',
										}}/>&nbsp;
										{feature.title}
									</div>} className={'feature'}>
										{feature.description}
									</Card>
								</Col>
							);
						})}
					</Row>
				</Content>
			</Wrapper>
		);
	}
}
